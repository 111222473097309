define('client/pods/components/billing-increase-modal/component', ['exports', 'client/utils/billing', 'client/utils/enums'], function (exports, _billing, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    elementId: 'billing-increase-modal',
    subscriptionPlan: _enums.SUBSCRIPTION_PLAN.PRO_YEARLY_CALENDAR_BILLED,
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),

    thresholdCount: Ember.computed('studentsCount', function () {
      return (0, _billing.nextAddonThreshold)(this.studentsCount);
    }),

    addonPrice: Ember.computed(function () {
      if (this.coupon) {
        return (0, _billing.calcDiscount)(this.coupon, this.subscriptionPlan.addonPrice);
      } else {
        return this.subscriptionPlan.addonPrice;
      }
    }),

    actions: {
      cancelBilling: function cancelBilling() {
        this.get('cancelBilling')();
      },
      saveBilling: function saveBilling() {
        this.get('saveBilling')();
      }
    }
  });
});