define('client/pods/components/user-account-menu/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['navbar-brand'],

    currentUser: Ember.inject.service(),

    // Close the dropdown if an element outside this component is clicked.
    closeDropdown: function closeDropdown(evt) {
      // Check for `this.$()` first because sometimes this handler fires
      // in testing and this component's element is not available.
      if (this.$() && this.$().find(evt.target).length === 0) {
        this.$('.dropdown-menu').hide();
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      // This doesn't seem like the best way to attach this event handler,
      // but since Ember Views were removed there is no way to attach events
      // to the whole page.
      Ember.$(document).on('click', this.closeDropdown.bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.$(document).off('click', this.closeDropdown);
    },


    actions: {
      toggleUserDropdown: function toggleUserDropdown() {
        this.$('.dropdown-menu').toggle();
      },
      openSettings: function openSettings() {
        this.$('.dropdown-menu').hide();
        this.get('openSettings')();
      },
      logOut: function logOut() {
        this.getAttr('logOut')();
      }
    }
  });
});