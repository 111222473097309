define('client/models/game-round', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    startedOn: _emberData.default.attr('date'),
    finishedOn: _emberData.default.attr('date'),
    numQuestions: _emberData.default.attr('number'),
    numCorrect: _emberData.default.attr('number'),
    numIncorrect: _emberData.default.attr('number'),
    score: _emberData.default.attr('number'),
    difficulty: _emberData.default.attr('number'),
    totalScore: _emberData.default.attr('number'),
    player: _emberData.default.belongsTo('user', { async: false }),
    game: _emberData.default.belongsTo('game', { async: false })
  });
});