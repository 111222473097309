define('client/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    username: _emberData.default.attr('string'),
    color: _emberData.default.attr('string'),
    teacher: _emberData.default.belongsTo('teacher', { async: false }),
    student: _emberData.default.belongsTo('student', { async: false }),
    gameRounds: _emberData.default.hasMany('gameRound', { async: false }),
    gradeStatistics: _emberData.default.hasMany('gradeStatistic', { async: false }),
    standardStatistics: _emberData.default.hasMany('standardStatistic', { async: false }),

    fullName: Ember.computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    }),

    shortName: Ember.computed('firstName', 'lastName', function () {
      var name = this.get('firstName');
      if (this.get('lastName')) {
        name += ' ' + this.get('lastName')[0] + '.';
      }
      return name;
    }),

    initials: Ember.computed('firstName', 'lastName', function () {
      var firstInitial = this.get('firstName')[0];
      var lastInitial = this.get('lastName')[0];
      return ('' + firstInitial + lastInitial).toUpperCase();
    }),

    avatarStyle: Ember.computed('color', function () {
      return Ember.String.htmlSafe('background-color: ' + this.get('color') + ';');
    })
  });
});