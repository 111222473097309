define('client/pods/components/full-stats-grouped-header/component', ['exports', 'ember-models-table/templates/components/models-table/grouped-header'], function (exports, _groupedHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _groupedHeader.default,
    tagName: 'tr',
    classNames: 'domain-headers'
  });
});