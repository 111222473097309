define('client/pods/billing/route', ['exports', 'client/mixins/permissions', 'client/utils/enums'], function (exports, _permissions, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_permissions.default, {
    studentManager: Ember.inject.service(),

    model: function model() {
      return this.get('studentManager').fetchStudents(false);
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        tab: _enums.ACCOUNT_TAB.BILLING.value,
        students: model
      });
    }
  });
});