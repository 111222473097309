define('client/pods/components/checkbox-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'input',
    attributeBindings: ['inputType:type'],

    inputType: 'checkbox',
    selected: false,

    change: function change() {
      this.toggleProperty('selected');
      this.getAttr('toggleSelection')(this.get('selected'));
    }
  });
});