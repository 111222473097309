define('client/pods/tags/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notifications: Ember.inject.service('notification-messages'),
    breadcrumbs: [{ route: 'teacher-dashboard', text: 'Dashboard' }, { route: 'group-admin', text: 'Class Admin' }, { text: 'Tags' }],

    actions: {
      addTag: function addTag() {
        var tag = this.store.createRecord('tag');
        this.get('tags').pushObject(tag);
      },
      deleteTag: function deleteTag(tag) {
        this.get('tags').removeObject(tag);
        tag.destroyRecord();
        this.get('notifications').success('Tag deleted successfully');
      },
      back: function back() {
        this.transitionToRoute('group-admin');
      },
      saveTag: function saveTag(isNew) {
        var message = 'Tag ' + (isNew ? 'create' : 'update') + 'd successfully';
        this.get('notifications').success(message);
      }
    }
  });
});