define('client/pods/components/roster-column-select/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    error: Ember.computed('changeset.error', function () {
      return this.get('changeset.error.' + this.field + '.validation');
    })
  });
});