define('client/services/student-manager', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),

    fetchStudents: function fetchStudents() {
      var includeUser = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      var filters = {
        is_demo: false,
        is_archived: false
      };

      if (includeUser) {
        filters.include = 'user';
      }

      return this.get('store').query('student', filters);
    },
    sortByName: function sortByName(students) {
      return students.sortBy('user.lastName', 'user.firstName');
    }
  });
});