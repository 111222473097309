define('client/models/student', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    gradeLevel: _emberData.default.attr('string'),
    language: _emberData.default.attr('string'),
    username: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    createdOn: _emberData.default.attr('date'),
    prelevel: _emberData.default.attr('string'),
    isArchived: _emberData.default.attr('boolean'),
    hasPlayedGames: _emberData.default.attr('boolean'),
    user: _emberData.default.belongsTo('user', { async: false }),
    school: _emberData.default.belongsTo('school'),
    groups: _emberData.default.hasMany('group', { async: true }),
    tags: _emberData.default.hasMany('tag', { async: false }),

    // We need these intermediate computed properties to make the
    // `group.sortedStudents` computed property.
    firstName: Ember.computed.alias('user.firstName'),
    lastName: Ember.computed.alias('user.lastName'),

    sortName: Ember.computed('user.{firstName,lastName}', function () {
      return this.get('user.lastName') + ' ' + this.get('user.firstName');
    }),

    shortGradeLevel: Ember.computed('gradeLevel', function () {
      if (this.get('gradeLevel') === 'Pre-kindergarten') {
        return 'PK';
      } else if (this.get('gradeLevel') === 'Kindergarten') {
        return 'K';
      } else {
        // Return just the grade number (e.g. 'Grade 3' --> '3')
        return this.get('gradeLevel').split(' ')[1];
      }
    })
  });
});