define('client/pods/components/custom-async-button/component', ['exports', 'ember-prop-types'], function (exports, _emberPropTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'button',
    classNameBindings: ['getClassNames'],
    attributeBindings: ['isDisabled:disabled'],

    getClassNames: Ember.computed('task.{isRunning}', function () {
      if (this.get('task.isRunning')) {
        return 'custom-async-button custom-async-button-loading';
      }
      return 'custom-async-button';
    }),

    propTypes: {
      action: _emberPropTypes.PropTypes.func({ required: true, updatable: false }),
      task: _emberPropTypes.PropTypes.EmberObject({ required: true, updatable: false }),
      default: _emberPropTypes.PropTypes.string({ required: true, updatable: true }),
      buttonDisabled: _emberPropTypes.PropTypes.bool({ required: false, updatable: true })
    },

    getDefaultProps: function getDefaultProps() {
      return {
        buttonDisabled: false
      };
    },


    isDisabled: Ember.computed.or('task.isRunning', 'buttonDisabled'),

    click: function click() {
      this.get('action')();
    }
  });
});