define('client/pods/components/group-students/component', ['exports', 'client/utils/enums'], function (exports, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'group-students',
    currentUser: Ember.inject.service(),
    selectedHeader: 'name',

    allStudentsSelected: Ember.computed('selectedStudents.length', 'filteredStudents.length', function () {
      return this.get('selectedStudents.length') === this.get('filteredStudents.length');
    }),

    someStudentsSelected: Ember.computed('selectedStudents.length', 'filteredStudents.length', function () {
      var selectedLen = this.get('selectedStudents.length');
      var filteredLen = this.get('filteredStudents.length');
      return selectedLen > 0 && selectedLen < filteredLen;
    }),

    noStudentsSelected: Ember.computed('selectedStudents.length', function () {
      return this.get('selectedStudents.length') === 0;
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this.send('sortByName', true);
    },
    setStudents: function setStudents(students, isAsc) {
      if (!isAsc) {
        students.reverse();
      }

      var tagIds = this.get('selectedTags').mapBy('id');

      if (Ember.isPresent(tagIds)) {
        students = students.filter(function (student) {
          return student.get('tags').mapBy('id').some(function (id) {
            return tagIds.includes(id);
          });
        });
      }

      this.set('filteredStudents', students);
    },


    actions: {
      sortByName: function sortByName(isAsc) {
        var students = this.get('students').sortBy('user.lastName', 'user.firstName');
        this.setStudents(students, isAsc);
      },
      sortByUsername: function sortByUsername(isAsc) {
        var students = this.get('students').sortBy('username');
        this.setStudents(students, isAsc);
      },
      sortByGrade: function sortByGrade(isAsc) {
        var students = this.get('students').toArray().sort(function (a, b) {
          var idxA = _enums.gradeLevels.indexOf(a.get('gradeLevel'));
          var idxB = _enums.gradeLevels.indexOf(b.get('gradeLevel'));
          return idxA - idxB;
        });
        this.setStudents(students, isAsc);
      },
      sortByPrelevel: function sortByPrelevel(isAsc) {
        var students = this.get('students').toArray().sort(function (a, b) {
          var prelevels = _enums.PRELEVEL.values();
          var idxA = prelevels.indexOf(a.get('prelevel'));
          var idxB = prelevels.indexOf(b.get('prelevel'));
          return idxA - idxB;
        });
        this.setStudents(students, isAsc);
      },
      selectAll: function selectAll(e) {
        if (e.target.checked) {
          this.set('selectedStudents', this.get('filteredStudents').toArray());
        } else {
          this.set('selectedStudents', []);
        }
      },
      selectStudent: function selectStudent(student, selected) {
        // It's important to make a copy of `selectedStudents` so we can `set`
        // the new value of it and stay within the Ember runloop. Tests will
        // fail intermittently otherwise.
        var students = this.get('selectedStudents').slice();
        if (selected) {
          students.pushObject(student);
        } else {
          students.removeObject(student);
        }
        this.set('selectedStudents', students);
      },
      editStudent: function editStudent() {
        this.get('editStudent').apply(undefined, arguments);
      },
      viewStudentReport: function viewStudentReport() {
        this.get('viewStudentReport').apply(undefined, arguments);
      },
      transferStudents: function transferStudents() {
        this.get('transferStudents').apply(undefined, arguments);
      },
      archiveStudents: function archiveStudents() {
        this.get('archiveStudents').apply(undefined, arguments);
      }
    }
  });
});