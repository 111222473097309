define('client/pods/components/student-detail/component', ['exports', 'client/utils/enums'], function (exports, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',
    classNames: 'student',
    currentUser: Ember.inject.service(),
    codeVisible: false,
    SHORT_PRELEVEL: _enums.SHORT_PRELEVEL,

    selected: Ember.computed('student', 'selectedStudents.[]', function () {
      return this.get('selectedStudents').includes(this.get('student'));
    }),

    tagsList: Ember.computed('student.tags.[]', function () {
      return this.get('student.tags').mapBy('name').sort().join(', ');
    }),

    actions: {
      toggleSelection: function toggleSelection(e) {
        this.get('selectStudent')(this.get('student'), e.target.checked);
      },
      toggleCode: function toggleCode() {
        this.toggleProperty('codeVisible');
      },
      editStudent: function editStudent() {
        this.getAttr('editStudent')(this.get('student'));
      },
      viewReport: function viewReport() {
        this.get('viewStudentReport')(this.get('student'));
      }
    }
  });
});