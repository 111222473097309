define('client/models/game', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    game: _emberData.default.attr('string'),
    subgame: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    maxDifficulty: _emberData.default.attr('number'),
    longTitle: _emberData.default.attr('string'),
    shortTitle: _emberData.default.attr('string'),
    shortSubtitle: _emberData.default.attr('string'),
    standards: _emberData.default.hasMany('standard'),
    rounds: _emberData.default.hasMany('gameRound', { async: false }),
    standardGamesStatistic: _emberData.default.hasMany('standardGamesStatistic', { async: true })
  });
});