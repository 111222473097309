define('client/pods/tags/route', ['exports', 'client/mixins/permissions'], function (exports, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_permissions.default, {
    model: function model() {
      return this.store.findAll('tag');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('tags', model.toArray().sortBy('name'));
    }
  });
});