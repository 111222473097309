define('client/pods/student-reports/route', ['exports', 'client/mixins/permissions', 'client/utils/enums'], function (exports, _permissions, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_permissions.default, {
    standardManager: Ember.inject.service(),
    studentManager: Ember.inject.service(),

    model: function model() {
      return Ember.RSVP.hash({
        students: this.get('studentManager').fetchStudents(),
        standards: this.get('standardManager').getReportingStandards()
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      model.selectedDate = moment().endOf('day').toDate();
      model.selectedGrades = [_enums.SHORT_GRADE.KINDERGARTEN.value, _enums.SHORT_GRADE.GRADE_1.value];
      controller.setProperties(model);
    }
  });
});