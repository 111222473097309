define('client/pods/components/date-range-filter/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'reports-filter',

    disable: [function (date) {
      // Disable all dates after end of today
      return moment(date).isAfter(moment().endOf('day'));
    }],

    actions: {
      select: function select(dates) {
        var date = dates[0];
        // Filter for all data until the very end of the date selected
        var dateEnd = moment(date).endOf('day').toDate();
        this.get('select')(dateEnd);
      }
    }
  });
});