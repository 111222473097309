define('client/utils/billing', ['exports', 'client/utils/enums'], function (exports, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.calcPlanPrice = calcPlanPrice;
  exports.calcAddonCount = calcAddonCount;
  exports.nextAddonThreshold = nextAddonThreshold;
  exports.calcDiscount = calcDiscount;


  var PRO = _enums.SUBSCRIPTION_PLAN.PRO_YEARLY_CALENDAR_BILLED;

  function calcPlanPrice(plan, numStudents) {
    var extraStudents = Math.max(numStudents - plan.baseQuantity, 0);
    var addonPrice = Math.ceil(extraStudents / plan.addonQuantity) * plan.addonPrice;
    return plan.basePrice + addonPrice;
  }

  function calcAddonCount(numStudents) {
    if (numStudents <= PRO.baseQuantity) {
      return 0;
    } else {
      var extraStudents = numStudents - PRO.baseQuantity;
      return Math.ceil(extraStudents / PRO.addonQuantity);
    }
  }

  function nextAddonThreshold(numStudents) {
    var numAddons = calcAddonCount(numStudents);
    return PRO.baseQuantity + numAddons * PRO.addonQuantity;
  }

  function calcDiscount(coupon, price) {
    if (coupon.discountType === _enums.COUPON_DISCOUNT.FIXED_AMOUNT.value) {
      return Math.max(price - coupon.discountAmount / 100, 0);
    } else {
      return price * (1 - coupon.discountPercentage / 100);
    }
  }
});