define('client/pods/components/tag-select/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'tag-select',
    title: 'Tags',
    tagOptions: [],
    tagSortAttrs: ['name'],

    sortedTags: Ember.computed.sort('tagOptions', 'tagSortAttrs'),

    placeholder: Ember.computed('canCreate', function () {
      return this.get('canCreate') ? 'Select or create tags' : 'Select tags';
    }),

    actions: {
      showCreateOption: function showCreateOption(term) {
        if (!this.get('canCreate')) {
          return false;
        }
        return !this.get('tagOptions').findBy('name', term);
      },
      onChange: function onChange(tags) {
        this.get('onChange')(tags);
      },
      onCreate: function onCreate(tag) {
        this.get('onCreate')(tag);
      }
    }
  });
});