define('client/pods/components/wizard-done/component', ['exports', 'client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    standardsHost: _environment.default.standardsHost,
    currentUser: Ember.inject.service()
  });
});