define('client/pods/components/sortable-table/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'table',
    classNames: 'sortable-table'
  });
});