define('client/models/standard-statistic', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    score: _emberData.default.attr('number'),
    numDataPoints: _emberData.default.attr('number'),
    endDate: _emberData.default.attr('date'),
    standard: _emberData.default.belongsTo('standard', { async: false }),
    player: _emberData.default.belongsTo('user', { async: false })
  });
});