define('client/pods/components/subscription-expired-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      goToDashboard: function goToDashboard() {
        this.get('goToDashboard')();
      },
      goToBilling: function goToBilling() {
        this.get('goToBilling')();
      }
    }
  });
});