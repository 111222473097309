define('client/pods/profile/route', ['exports', 'ember-changeset', 'ember-changeset-validations', 'client/mixins/permissions', 'client/utils/enums'], function (exports, _emberChangeset, _emberChangesetValidations, _permissions, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_permissions.default, {
    currentUser: Ember.inject.service(),

    setupController: function setupController(controller) {
      var teacher = this.get('currentUser.teacher');
      var changeset = new _emberChangeset.default(Ember.Object.create({
        firstName: teacher.get('user.firstName'),
        lastName: teacher.get('user.lastName'),
        gradeLevels: teacher.get('gradeLevels'),
        email: teacher.get('user.email'),
        job: teacher.get('job')
      }), (0, _emberChangesetValidations.default)(controller.get('validations')), controller.get('validations'), { skipValidate: true });

      controller.setProperties({
        tab: _enums.ACCOUNT_TAB.PROFILE.value,
        changeset: changeset,
        teacher: teacher
      });
    }
  });
});