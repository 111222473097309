define('client/pods/special-offer/controller', ['exports', 'client/pods/register/controller'], function (exports, _controller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _controller.default.extend({
    queryParams: ['offer', {
      couponCode: 'coupon',
      conference: 'conf'
    }]
  });
});