define('client/pods/login/route', ['exports', 'ember-changeset', 'ember-changeset-validations', 'client/mixins/permissions', 'client/utils/enums'], function (exports, _emberChangeset, _emberChangesetValidations, _permissions, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_permissions.default, {
    setupController: function setupController(controller) {
      var changeset = new _emberChangeset.default(Ember.Object.create(), (0, _emberChangesetValidations.default)(controller.validations), controller.validations, { skipValidate: true });
      controller.setProperties({
        changeset: changeset,
        tab: _enums.AUTH_TAB.EMAIL.value
      });
    },
    deactivate: function deactivate() {
      this._super();
      // Set this flag here so that the sign in button state doesn't
      // reset before the page transitions, which would look weird.
      this.controller.set('isAuthenticating', false);
    }
  });
});