define('client/pods/components/wizard-students/component', ['exports', 'client/utils/enums'], function (exports, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    notifications: Ember.inject.service('notification-messages'),
    STUDENT_WIZARD_TAB: _enums.STUDENT_WIZARD_TAB,
    tab: _enums.STUDENT_WIZARD_TAB.SINGLE.value,

    isRosterEmpty: Ember.computed.equal('students.length', 0),

    actions: {
      deleteStudent: function deleteStudent(student) {
        var _this = this;

        var name = student.get('firstName') + ' ' + student.get('lastName');
        this.get('students').removeObject(student);
        student.destroyRecord().then(function () {
          _this.get('notifications').success('Removed ' + name);
        });
      },
      forward: function forward() {
        var teacher = this.get('currentUser.teacher');
        teacher.set('finishedSetup', true);
        teacher.save();
        this.get('nextPage')();
      },
      updateStudents: function updateStudents(students) {
        this.set('students', students);
      },
      scrollRoster: function scrollRoster() {
        // Scroll to bottom of student roster
        var rosterEl = this.$('#wizard-student-roster');
        var scrollHeight = rosterEl[0].scrollHeight;
        rosterEl.animate({ scrollTop: scrollHeight });
      }
    }
  });
});