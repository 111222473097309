define('client/initializers/asset-map', ['exports', 'client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.deferReadiness();

    var AssetMap = Ember.Object.extend();

    var isDevEnv = ['development', 'test'].includes(_environment.default.environment);

    var promise = new Ember.RSVP.Promise(function (resolve, reject) {
      if (isDevEnv) {
        // `assetMap.json` will not exist in dev/test environments, so just
        // return the plain name
        reject();
      } else {
        // Ember CLI will automatically substitute this path with the correct
        // fingerprinted one in production
        var assetMapURL = '/assetMap.json';
        Ember.$.getJSON(assetMapURL, resolve).fail(reject);
      }
    });

    promise.then(function (assetMap) {
      // We found the asset map
      AssetMap.reopen({
        assetMap: assetMap,
        resolve: function resolve(name) {
          // Lookup asset in asset map; if not found, try the asset name itself
          return '' + assetMap.prepend + assetMap.assets[name] || name;
        }
      });
    }, function () {
      // The asset map was not found or doesn't exist
      AssetMap.reopen({
        resolve: function resolve(name) {
          // Add on the `rootURL` only for development environments.
          // In production the prepended URL is set in ember-cli-build.js.
          return _environment.default.rootURL + name;
        }
      });
    }).then(function () {
      application.register('service:assetMap', AssetMap, { singleton: true });
      application.advanceReadiness();
    });
  }

  exports.default = {
    name: 'asset-map',
    initialize: initialize
  };
});