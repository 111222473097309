define('client/mixins/intro', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    applicationController: Ember.inject.controller('application'),
    hintsEnabled: Ember.computed.readOnly('applicationController.hintsEnabled'),

    actions: {
      onTourCancel: function onTourCancel() {
        this.send('updateHintMode', false);
      }
    }
  });
});