define('client/pods/components/validation-error/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['validation-error'],

    errorArray: Ember.computed('error', function () {
      return Ember.makeArray(this.get('error'));
    })
  });
});