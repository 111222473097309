define('client/initializers/window-service', ['exports', 'ember-window-mock/initializers/window-service'], function (exports, _windowService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _windowService.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _windowService.initialize;
    }
  });
});