define('client/models/standard', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    asnId: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    shortDesc: _emberData.default.attr('string'),
    comments: _emberData.default.attr('string'),
    depth: _emberData.default.attr('number'),
    position: _emberData.default.attr('number'),
    domainOrder: _emberData.default.attr('number'),
    parent: _emberData.default.belongsTo('standard', { inverse: 'children' }),
    children: _emberData.default.hasMany('standard', { inverse: 'parent' }),
    games: _emberData.default.hasMany('game', { async: false }),
    postrequisites: _emberData.default.hasMany('standard', {
      async: false,
      inverse: 'prerequisites'
    }),
    prerequisites: _emberData.default.hasMany('standard', {
      async: false,
      inverse: 'postrequisites'
    }),
    standardsSet: _emberData.default.belongsTo('standardsSet', { async: false }),
    standardStatistics: _emberData.default.hasMany('standardStatistic', { async: false }),

    shortCode: Ember.computed('code', function () {
      var parts = this.get('code').split('.');
      if (parts.length > 2) {
        // Exclude the subdomain letter from the code.
        return parts[0] + '.' + parts[1] + '.' + parts[3];
      } else {
        return this.get('code');
      }
    }),

    grade: Ember.computed('code', function () {
      var parts = this.get('code').split('.');
      return parts[0];
    }),

    domain: Ember.computed('code', function () {
      var parts = this.get('code').split('.');
      return parts[0] + '.' + parts[1];
    }),

    subdomainNumber: Ember.computed('code', function () {
      var parts = this.get('code').split('.');
      return parts[3];
    })
  });
});