define('client/models/standards-set', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    gradeLevels: _emberData.default.attr(),
    standards: _emberData.default.hasMany('standard', { async: false })
  });
});