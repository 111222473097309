define('client/utils/get-score-rating', ['exports', 'client/utils/enums'], function (exports, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getScoreRating;
  function getScoreRating(score) {
    if (Ember.typeOf(score) !== 'number') {
      return null;
    }

    if (score >= 75) {
      return _enums.SCORE_RATING.EXCELLENT;
    } else if (score < 75 && score >= 50) {
      return _enums.SCORE_RATING.GOOD;
    } else if (score < 50 && score >= 25) {
      return _enums.SCORE_RATING.FAIR;
    } else {
      return _enums.SCORE_RATING.POOR;
    }
  }
});