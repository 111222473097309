define('client/adapters/application', ['exports', 'ember-simple-auth/mixins/data-adapter-mixin', 'ember-simple-auth-token/mixins/token-authorizer', 'ember-data', 'client/config/environment'], function (exports, _dataAdapterMixin, _tokenAuthorizer, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, _tokenAuthorizer.default, {
    host: _environment.default.httpHost,
    coalesceFindRequests: true
  });
});