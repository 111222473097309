define('client/pods/components/modal-window/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    canClose: true,

    actions: {
      closeModal: function closeModal() {
        this.getAttr('closeModal')();
      }
    }
  });
});