define('client/models/teacher', ['exports', 'ember-data', 'client/utils/billing', 'client/utils/enums'], function (exports, _emberData, _billing, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    job: _emberData.default.attr('string'),
    gradeLevels: _emberData.default.attr(),
    selectiveMode: _emberData.default.attr('boolean'),
    loginMethod: _emberData.default.attr('string'),
    finishedSetup: _emberData.default.attr('boolean'),
    useClassCode: _emberData.default.attr('boolean'),
    // Don't use 'boolean' since this field could be null
    useSimpleClassCode: _emberData.default.attr(),
    standardsBody: _emberData.default.attr('string'),
    subscriptionPlan: _emberData.default.attr('string'),
    subscriptionStatus: _emberData.default.attr('string'),
    hasActiveSubscription: _emberData.default.attr('boolean'),
    trialEnd: _emberData.default.attr('date'),
    cardBrand: _emberData.default.attr('string'),
    last4: _emberData.default.attr('string'),
    hasPaymentMethod: _emberData.default.attr('boolean'),
    yearStart: _emberData.default.attr(),
    yearEnd: _emberData.default.attr(),
    user: _emberData.default.belongsTo('user', { async: false }),
    school: _emberData.default.belongsTo('school', { async: true }),
    groups: _emberData.default.hasMany('group', { async: false }),
    initialStudentNumber: _emberData.default.attr('number'),
    seenBillingNotice: _emberData.default.attr('boolean'),
    hasStudentActivity: _emberData.default.attr('boolean'),
    coupon: _emberData.default.attr('string'),

    hasSubscription: Ember.computed.notEmpty('subscriptionPlan'),

    hasFreePlan: Ember.computed.equal('subscriptionPlan', _enums.SUBSCRIPTION_PLAN.FREE.value),

    hasProPlan: Ember.computed('subscriptionPlan', function () {
      return [_enums.SUBSCRIPTION_PLAN.PRO.value, _enums.SUBSCRIPTION_PLAN.PRO_YEARLY.value, _enums.SUBSCRIPTION_PLAN.PRO_YEARLY_CALENDAR_BILLED.value].includes(this.get('subscriptionPlan'));
    }),

    hasProYearlyPlan: Ember.computed('subscriptionPlan', function () {
      return [_enums.SUBSCRIPTION_PLAN.PRO_YEARLY.value, _enums.SUBSCRIPTION_PLAN.PRO_YEARLY_CALENDAR_BILLED.value].includes(this.get('subscriptionPlan'));
    }),

    hasProTrial: Ember.computed('hasProPlan', 'subscriptionStatus', function () {
      return this.get('hasProPlan') && this.get('subscriptionStatus') === _enums.SUBSCRIPTION_STATUS.IN_TRIAL.value;
    }),

    isPaying: Ember.computed.and('hasProPlan', 'hasActiveSubscription', 'hasPaymentMethod'),

    hasExpiredSubscription: Ember.computed('subscriptionPlan', 'hasActiveSubscription', function () {
      return Ember.isPresent(this.get('subscriptionPlan')) && !this.get('hasActiveSubscription');
    }),

    hasFreeYearCoupon: Ember.computed('coupon', function () {
      var coupon = this.get('coupon');
      return coupon && (coupon.toLowerCase() === _enums.COUPON.FREE_YEAR.value.toLowerCase() || this.get('subscriptionStatus') === _enums.SUBSCRIPTION_STATUS.ACTIVE.value || this.get('subscriptionStatus') === _enums.SUBSCRIPTION_STATUS.NON_RENEWING.value);
    }),

    needsBillingNotice: function needsBillingNotice(studentCount) {
      var initNum = this.get('initialStudentNumber');

      if (Ember.isEmpty(initNum) || this.get('seenBillingNotice')) {
        return false;
      }

      return studentCount >= (0, _billing.nextAddonThreshold)(studentCount);
    }
  });
});