define('client/utils/errors', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.parseErrors = parseErrors;
  function parseErrors(data) {
    return (data.errors || []).map(function (error) {
      var propName = error.source.pointer.split('/').get('lastObject');
      return {
        attr: Ember.String.camelize(propName),
        message: error.detail
      };
    });
  }
});