define('client/pods/new-student/route', ['exports', 'client/mixins/permissions'], function (exports, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_permissions.default, {
    studentManager: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    model: function model() {
      var couponCode = this.get('currentUser.teacher.coupon');
      return Ember.RSVP.hash({
        groups: this.store.query('group', { is_demo: false }),
        tags: this.store.findAll('tag'),
        students: this.get('studentManager').fetchStudents(),
        coupon: couponCode ? this.store.find('coupon', couponCode) : ''
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var teacher = this.get('currentUser.user.teacher');
      var students = model.students.toArray();

      controller.setProperties({
        teacher: teacher,
        students: students,
        group: model.groups.get('firstObject'),
        tags: model.tags.toArray(),
        coupon: model.coupon
      });
    }
  });
});