define('client/components/models-table/global-filter', ['exports', 'ember-models-table/components/models-table/global-filter'], function (exports, _globalFilter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _globalFilter.default;
    }
  });
});