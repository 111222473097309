define('client/services/window-proxy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    getLocation: function getLocation() {
      return window.location.href;
    },
    setLocation: function setLocation(url) {
      window.location.replace(url);
    }
  });
});