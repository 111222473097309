define('client/authenticators/google', ['exports', 'ember-simple-auth-token/authenticators/token', 'client/config/environment'], function (exports, _token, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _token.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.serverTokenEndpoint = _environment.default.httpHost + '/auth/google/teacher';
    }
  });
});