define('client/pods/components/form-row/component', ['exports', 'ember-block-slots', 'ember-prop-types'], function (exports, _emberBlockSlots, _emberPropTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberBlockSlots.default, {
    classNames: ['form-row'],
    propTypes: {
      label: _emberPropTypes.PropTypes.string.isRequired,
      required: _emberPropTypes.PropTypes.bool.isRequired,
      error: _emberPropTypes.PropTypes.arrayOf(_emberPropTypes.PropTypes.string)
    }
  });
});