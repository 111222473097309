define('client/pods/edit-student/route', ['exports', 'client/mixins/permissions'], function (exports, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_permissions.default, {
    model: function model(params) {
      return Ember.RSVP.hash({
        groups: this.store.query('group', { is_demo: false }),
        student: this.store.findRecord('student', params.student_id, {
          include: 'user'
        }),
        tags: this.store.findAll('tag')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties({
        group: model.groups.get('firstObject'),
        student: model.student,
        tags: model.tags.toArray()
      });
    }
  });
});