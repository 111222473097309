define('client/models/group', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    isDemo: _emberData.default.attr('boolean'),
    loginCode: _emberData.default.attr('string'),
    simpleLoginCode: _emberData.default.attr('string'),
    teacher: _emberData.default.belongsTo('teacher', { async: false }),
    students: _emberData.default.hasMany('student', { async: true }),

    // Computed properties cannot be nested like `students.@each.user.firstName`,
    // so we have to use the `student.firstName` intermediate property.
    sortedStudents: Ember.computed('students.@each.{firstName,lastName}', function () {
      return this.get('students').sortBy('lastName', 'firstName');
    }),

    formattedLoginCode: Ember.computed('loginCode', function () {
      var code = this.get('loginCode');
      return code.slice(0, 3) + ' ' + code.slice(3, 6) + ' ' + code.slice(6, 9);
    })
  });
});