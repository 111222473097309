define('client/models/token', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    token: _emberData.default.attr('string'),
    createdOn: _emberData.default.attr('date'),
    expiresOn: _emberData.default.attr('date')
  });
});