define('client/pods/components/full-stats-table-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',

    actions: {
      sendAction: function sendAction(actionName, obj) {
        this.get('sendAction')(actionName, obj);
      }
    }
  });
});