define('client/pods/preferences/controller', ['exports', 'client/utils/enums'], function (exports, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    notifications: Ember.inject.service('notification-messages'),
    ACCOUNT_TAB: _enums.ACCOUNT_TAB,
    STANDARDS_BODY: _enums.STANDARDS_BODY,

    breadcrumbs: [{ route: 'teacher-dashboard', text: 'Dashboard' }, { text: 'Account' }],

    teacher: Ember.computed.alias('currentUser.user.teacher'),

    actions: {
      selectTab: function selectTab(val) {
        if (val === _enums.ACCOUNT_TAB.BILLING.value) {
          this.transitionToRoute('billing');
        } else if (val === _enums.ACCOUNT_TAB.PROFILE.value) {
          this.transitionToRoute('profile');
        }
      },
      savePreferences: function savePreferences() {
        var _this = this;

        return this.get('teacher').save().then(function () {
          _this.get('notifications').success('Preferences saved');
        });
      }
    }
  });
});