define('client/pods/components/loading-element/component', ['exports', 'ember-prop-types'], function (exports, _emberPropTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    propTypes: {
      loader: _emberPropTypes.PropTypes.oneOf(['ellipsis']).isRequired
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var loader = this.get('loader');
      this.set(loader, true);
    }
  });
});