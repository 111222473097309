define('client/pods/not-found/route', ['exports', 'client/mixins/permissions'], function (exports, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_permissions.default, {
    permissions: [],
    currentUser: Ember.inject.service(),

    setupController: function setupController(controller) {
      controller.set('teacher', this.get('currentUser.user.teacher'));
    }
  });
});