define('client/pods/application/route', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'client/config/environment', 'client/utils/enums'], function (exports, _applicationRouteMixin, _environment, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    routeAfterAuthentication: _environment.default.routeAfterAuthentication,
    session: Ember.inject.service(),

    enableHintMode: function enableHintMode() {
      this.controller.set('hintsEnabled', true);
    },
    disableHintMode: function disableHintMode() {
      this.controller.set('hintsEnabled', false);
    },
    setupController: function setupController(controller) {
      controller.set('route', this.get('currentRouteName'));
    },
    sessionAuthenticated: function sessionAuthenticated() {
      this._super.apply(this, arguments);
      window.heap.identify(this.get('session.data.authenticated.user'), 'ID');
    },
    sessionInvalidated: function sessionInvalidated() {
      this._super.apply(this, arguments);
      window.heap.resetIdentity();
    },


    actions: {
      transitionToRoute: function transitionToRoute() {
        this.transitionTo.apply(this, arguments);
      },
      error: function error(_error, transition) {
        _error = _error.errors[0];
        if (_error.status === 0) {
          this.transitionTo('dashboard');
        } else if (_error.status == 403) {
          //go to some default route
          transition.abort();
        } else if (_error.status == 400) {
          //handle 400
          this.transitionTo('dashboard');
        } else if (_error.status == 401) {
          //handle 401
          transition.abort();
        } else if (_error.status == 404) {
          transition.abort();
        } else if (_error.status == 500) {
          this.transitionTo('dashboard');
        }
      },
      didTransition: function didTransition() {
        if (this.get('subscriptionExpired')) {
          this.controller.set('modal', _enums.MODAL.SUBSCRIPTION_EXPIRED.value);
          this.set('subscriptionExpired', false);
        } else {
          this.controller.set('modal', '');
        }
        if (this.get('coupon') != null) {
          setTimeout(function () {
            this.transitionTo('dashboard');
          }, 6000);
        }
      },
      toggleHintMode: function toggleHintMode() {
        if (this.controller.get('hintsEnabled')) {
          this.disableHintMode();
        } else {
          this.enableHintMode();
        }
      },
      updateHintMode: function updateHintMode(enabled) {
        if (enabled) {
          this.enableHintMode();
        } else {
          this.disableHintMode();
        }
      },
      setModal: function setModal(val) {
        this.controller.set('modal', val);
      },
      showSubExpiredModal: function showSubExpiredModal() {
        this.set('subscriptionExpired', true);
      }
    }
  });
});