define('client/pods/register/route', ['exports', 'ember-changeset', 'ember-changeset-validations', 'client/mixins/permissions', 'client/utils/enums'], function (exports, _emberChangeset, _emberChangesetValidations, _permissions, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_permissions.default, {
    setupController: function setupController(controller) {
      var emailChangeset = new _emberChangeset.default(Ember.Object.create(), (0, _emberChangesetValidations.default)(controller.get('emailValidations')), controller.get('emailValidations'), { skipValidate: true });
      controller.setProperties({
        emailChangeset: emailChangeset,
        tab: _enums.AUTH_TAB.EMAIL.value,
        offer: _enums.OFFER.NO_CREDIT_CARD.value
      });
    }
  });
});