define('client/utils/ajax', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (store, resource, endpoint, method, payload) {
    var adapter = store.adapterFor('application');
    var url = adapter.buildURL(resource) + '/' + endpoint;
    return adapter.ajax(url, method, { data: payload });
  };
});