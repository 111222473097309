define('client/pods/components/content-tabs/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'content-tabs',

    actions: {
      selectTab: function selectTab(tab) {
        this.set('tab', tab);
        this.get('selectTab')(tab);
      }
    }
  });
});