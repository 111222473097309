define('client/components/-ember-table-private/simple-checkbox', ['exports', 'ember-table/components/-private/simple-checkbox'], function (exports, _simpleCheckbox) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _simpleCheckbox.default;
    }
  });
});