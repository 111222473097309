define('client/adapters/teacher', ['exports', 'client/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    // Turn off background reload so that the currently authenticated teacher
    // does not fetch on every new route. This data will probably not change that
    // often, so it's safe to turn the auto-refresh off.
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    }
  });
});