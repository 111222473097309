define('client/pods/components/standards-body-filter/component', ['exports', 'client/utils/enums'], function (exports, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    STANDARDS_BODY: _enums.STANDARDS_BODY,

    actions: {
      onChange: function onChange() {
        this.get('updateStandards').apply(undefined, arguments);
      }
    }
  });
});