define('client/pods/preferences/route', ['exports', 'client/mixins/permissions', 'client/utils/enums'], function (exports, _permissions, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_permissions.default, {
    setupController: function setupController(controller) {
      controller.set('tab', _enums.ACCOUNT_TAB.PREFERENCES.value);
    }
  });
});