define('client/pods/components/intro-tour/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      var tour = new Shepherd.Tour({
        defaults: {
          classes: ['shepherd', 'shepherd-open', 'shepherd-theme-arrows', 'shepherd-transparent-text'].join(' ')
        }
      });
      this.set('tour', tour);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('enabled')) {
        this.showSteps();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this._resetTour();
    },
    showSteps: function showSteps() {
      var _this = this;

      var tour = this.get('tour');

      this._resetTour();

      var steps = this.get('steps').reject(function (step) {
        var attachTo = step.attachTo;
        // Guard against tutorial steps that may exist conditionally
        return Ember.isPresent(attachTo) && Ember.$(attachTo).length === 0;
      });

      steps.forEach(function (step, idx) {
        var attachTo = step.attachTo;
        var buttons = _this._getStepButtons(steps, step, idx);
        tour.addStep(idx, {
          text: step.text,
          attachTo: attachTo + ' ' + step.position,
          buttons: buttons
        });
        // Get the step object we just added
        var stepObj = tour.steps.slice(-1)[0];
        if (Ember.isPresent(attachTo)) {
          // These functions serve to highlight the current element
          stepObj.on('before-show', function () {
            Ember.$(attachTo).addClass('shepherd-modal');
          });
          stepObj.on('hide', function () {
            Ember.$(attachTo).removeClass('shepherd-modal');
          });
        }
      });

      tour.on('cancel', function () {
        _this._resetTour();
        if (_this.get('onCancel')) {
          _this.get('onCancel')();
        }
      });

      tour.on('complete', function () {
        _this._resetTour();
        if (_this.get('onComplete')) {
          _this.get('onComplete')();
        }
      });

      // Add an overlay to darken the content we're not focusing on
      Ember.$('body').append('<div id="shepherdOverlay"></div>');

      tour.start();
    },
    _getStepButtons: function _getStepButtons(steps, step, idx) {
      var tour = this.get('tour');

      var buttons = [{
        text: Ember.isPresent(step.exitButtonText) ? step.exitButtonText : 'Exit',
        classes: 'shepherd-button-secondary shepherd-button-exit',
        action: tour.cancel
      }];

      var showBackButton = Ember.isPresent(step.showBackButton) ? step.showBackButton : true;

      if (idx !== 0 && showBackButton) {
        buttons.push({
          text: Ember.isPresent(step.backButtonText) ? step.backButtonText : 'Back',
          classes: 'shepherd-button-primary',
          action: tour.back
        });
      }

      var showNextButton = Ember.isPresent(step.showNextButton) ? step.showNextButton : true;

      if (showNextButton) {
        var nextButtonText = void 0;
        if (Ember.isPresent(step.nextButtonText)) {
          nextButtonText = step.nextButtonText;
        } else {
          nextButtonText = idx === steps.length - 1 ? 'Done' : 'Next';
        }
        buttons.push({
          text: nextButtonText,
          classes: 'shepherd-button-primary shepherd-button-next',
          action: tour.next
        });
      }

      return buttons;
    },
    _resetTour: function _resetTour() {
      var tour = this.get('tour');
      tour.steps.forEach(function (step) {
        step.destroy();
      });
      tour.steps = [];
      Ember.$('#shepherdOverlay').remove();
    }
  });
});