define("client/utils/colors", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.red = red;
  exports.yellow = yellow;
  exports.green = green;
  exports.mountainMeadow = mountainMeadow;
  exports.blue = blue;
  exports.pelorous = pelorous;
  function red(alpha) {
    return rgba(226, 43, 23, alpha);
  }

  function yellow(alpha) {
    return rgba(254, 204, 47, alpha);
  }

  function green(alpha) {
    return rgba(80, 161, 1, alpha);
  }

  function mountainMeadow(alpha) {
    return rgba(51, 191, 178, alpha);
  }

  function blue(alpha) {
    return rgba(70, 194, 245, alpha);
  }

  function pelorous(alpha) {
    return rgba(28, 155, 253, alpha);
  }

  function rgba(r, g, b, a) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + a + ")";
  }
});