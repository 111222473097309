define('client/utils/enums', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var gradeLevels = exports.gradeLevels = ['Pre-kindergarten', 'Kindergarten', 'Grade 1', 'Grade 2', 'Grade 3', 'Grade 4', 'Grade 5', 'Grade 6', 'Grade 7', 'Grade 8', 'Grade 9', 'Grade 10', 'Grade 11', 'Grade 12'];

  var STATE = exports.STATE = {
    AK: 'Alaska',
    AL: 'Alabama',
    AR: 'Arkansas',
    AZ: 'Arizona',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DC: 'District of Columbia',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    IA: 'Iowa',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    MA: 'Massachusetts',
    MD: 'Maryland',
    ME: 'Maine',
    MI: 'Michigan',
    MN: 'Minnesota',
    MO: 'Missouri',
    MS: 'Mississippi',
    MT: 'Montana',
    NC: 'North Carolina',
    ND: 'North Dakota',
    NE: 'Nebraska',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NV: 'Nevada',
    NY: 'New York',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VA: 'Virginia',
    VT: 'Vermont',
    WA: 'Washington',
    WI: 'Wisconsin',
    WV: 'West Virginia',
    WY: 'Wyoming'
  };

  var countryData = [{
    "code": "AF",
    "name": "Afghanistan"
  }, {
    "code": "AX",
    "name": "Åland Islands"
  }, {
    "code": "AL",
    "name": "Albania"
  }, {
    "code": "DZ",
    "name": "Algeria"
  }, {
    "code": "AS",
    "name": "American Samoa"
  }, {
    "code": "AD",
    "name": "Andorra"
  }, {
    "code": "AO",
    "name": "Angola"
  }, {
    "code": "AI",
    "name": "Anguilla"
  }, {
    "code": "AQ",
    "name": "Antarctica"
  }, {
    "code": "AG",
    "name": "Antigua and Barbuda"
  }, {
    "code": "AR",
    "name": "Argentina"
  }, {
    "code": "AM",
    "name": "Armenia"
  }, {
    "code": "AW",
    "name": "Aruba"
  }, {
    "code": "AU",
    "name": "Australia"
  }, {
    "code": "AT",
    "name": "Austria"
  }, {
    "code": "AZ",
    "name": "Azerbaijan"
  }, {
    "code": "BS",
    "name": "Bahamas"
  }, {
    "code": "BH",
    "name": "Bahrain"
  }, {
    "code": "BD",
    "name": "Bangladesh"
  }, {
    "code": "BB",
    "name": "Barbados"
  }, {
    "code": "BY",
    "name": "Belarus"
  }, {
    "code": "BE",
    "name": "Belgium"
  }, {
    "code": "BZ",
    "name": "Belize"
  }, {
    "code": "BJ",
    "name": "Benin"
  }, {
    "code": "BM",
    "name": "Bermuda"
  }, {
    "code": "BT",
    "name": "Bhutan"
  }, {
    "code": "BO",
    "name": "Bolivia, Plurinational State of"
  }, {
    "code": "BQ",
    "name": "Bonaire, Sint Eustatius and Saba"
  }, {
    "code": "BA",
    "name": "Bosnia and Herzegovina"
  }, {
    "code": "BW",
    "name": "Botswana"
  }, {
    "code": "BV",
    "name": "Bouvet Island"
  }, {
    "code": "BR",
    "name": "Brazil"
  }, {
    "code": "IO",
    "name": "British Indian Ocean Territory"
  }, {
    "code": "BN",
    "name": "Brunei Darussalam"
  }, {
    "code": "BG",
    "name": "Bulgaria"
  }, {
    "code": "BF",
    "name": "Burkina Faso"
  }, {
    "code": "BI",
    "name": "Burundi"
  }, {
    "code": "KH",
    "name": "Cambodia"
  }, {
    "code": "CM",
    "name": "Cameroon"
  }, {
    "code": "CA",
    "name": "Canada"
  }, {
    "code": "CV",
    "name": "Cape Verde"
  }, {
    "code": "KY",
    "name": "Cayman Islands"
  }, {
    "code": "CF",
    "name": "Central African Republic"
  }, {
    "code": "TD",
    "name": "Chad"
  }, {
    "code": "CL",
    "name": "Chile"
  }, {
    "code": "CN",
    "name": "China"
  }, {
    "code": "CX",
    "name": "Christmas Island"
  }, {
    "code": "CC",
    "name": "Cocos (Keeling) Islands"
  }, {
    "code": "CO",
    "name": "Colombia"
  }, {
    "code": "KM",
    "name": "Comoros"
  }, {
    "code": "CG",
    "name": "Congo"
  }, {
    "code": "CD",
    "name": "Congo, the Democratic Republic of the"
  }, {
    "code": "CK",
    "name": "Cook Islands"
  }, {
    "code": "CR",
    "name": "Costa Rica"
  }, {
    "code": "CI",
    "name": "Côte d'Ivoire"
  }, {
    "code": "HR",
    "name": "Croatia"
  }, {
    "code": "CU",
    "name": "Cuba"
  }, {
    "code": "CW",
    "name": "Curaçao"
  }, {
    "code": "CY",
    "name": "Cyprus"
  }, {
    "code": "CZ",
    "name": "Czech Republic"
  }, {
    "code": "DK",
    "name": "Denmark"
  }, {
    "code": "DJ",
    "name": "Djibouti"
  }, {
    "code": "DM",
    "name": "Dominica"
  }, {
    "code": "DO",
    "name": "Dominican Republic"
  }, {
    "code": "EC",
    "name": "Ecuador"
  }, {
    "code": "EG",
    "name": "Egypt"
  }, {
    "code": "SV",
    "name": "El Salvador"
  }, {
    "code": "GQ",
    "name": "Equatorial Guinea"
  }, {
    "code": "ER",
    "name": "Eritrea"
  }, {
    "code": "EE",
    "name": "Estonia"
  }, {
    "code": "ET",
    "name": "Ethiopia"
  }, {
    "code": "FK",
    "name": "Falkland Islands (Malvinas)"
  }, {
    "code": "FO",
    "name": "Faroe Islands"
  }, {
    "code": "FJ",
    "name": "Fiji"
  }, {
    "code": "FI",
    "name": "Finland"
  }, {
    "code": "FR",
    "name": "France"
  }, {
    "code": "GF",
    "name": "French Guiana"
  }, {
    "code": "PF",
    "name": "French Polynesia"
  }, {
    "code": "TF",
    "name": "French Southern Territories"
  }, {
    "code": "GA",
    "name": "Gabon"
  }, {
    "code": "GM",
    "name": "Gambia"
  }, {
    "code": "GE",
    "name": "Georgia"
  }, {
    "code": "DE",
    "name": "Germany"
  }, {
    "code": "GH",
    "name": "Ghana"
  }, {
    "code": "GI",
    "name": "Gibraltar"
  }, {
    "code": "GR",
    "name": "Greece"
  }, {
    "code": "GL",
    "name": "Greenland"
  }, {
    "code": "GD",
    "name": "Grenada"
  }, {
    "code": "GP",
    "name": "Guadeloupe"
  }, {
    "code": "GU",
    "name": "Guam"
  }, {
    "code": "GT",
    "name": "Guatemala"
  }, {
    "code": "GG",
    "name": "Guernsey"
  }, {
    "code": "GN",
    "name": "Guinea"
  }, {
    "code": "GW",
    "name": "Guinea-Bissau"
  }, {
    "code": "GY",
    "name": "Guyana"
  }, {
    "code": "HT",
    "name": "Haiti"
  }, {
    "code": "HM",
    "name": "Heard Island and McDonald Islands"
  }, {
    "code": "VA",
    "name": "Holy See (Vatican City State)"
  }, {
    "code": "HN",
    "name": "Honduras"
  }, {
    "code": "HK",
    "name": "Hong Kong"
  }, {
    "code": "HU",
    "name": "Hungary"
  }, {
    "code": "IS",
    "name": "Iceland"
  }, {
    "code": "IN",
    "name": "India"
  }, {
    "code": "ID",
    "name": "Indonesia"
  }, {
    "code": "IR",
    "name": "Iran, Islamic Republic of"
  }, {
    "code": "IQ",
    "name": "Iraq"
  }, {
    "code": "IE",
    "name": "Ireland"
  }, {
    "code": "IM",
    "name": "Isle of Man"
  }, {
    "code": "IL",
    "name": "Israel"
  }, {
    "code": "IT",
    "name": "Italy"
  }, {
    "code": "JM",
    "name": "Jamaica"
  }, {
    "code": "JP",
    "name": "Japan"
  }, {
    "code": "JE",
    "name": "Jersey"
  }, {
    "code": "JO",
    "name": "Jordan"
  }, {
    "code": "KZ",
    "name": "Kazakhstan"
  }, {
    "code": "KE",
    "name": "Kenya"
  }, {
    "code": "KI",
    "name": "Kiribati"
  }, {
    "code": "KP",
    "name": "Korea, Democratic People's Republic of"
  }, {
    "code": "KR",
    "name": "Korea, Republic of"
  }, {
    "code": "KW",
    "name": "Kuwait"
  }, {
    "code": "KG",
    "name": "Kyrgyzstan"
  }, {
    "code": "LA",
    "name": "Lao People's Democratic Republic"
  }, {
    "code": "LV",
    "name": "Latvia"
  }, {
    "code": "LB",
    "name": "Lebanon"
  }, {
    "code": "LS",
    "name": "Lesotho"
  }, {
    "code": "LR",
    "name": "Liberia"
  }, {
    "code": "LY",
    "name": "Libya"
  }, {
    "code": "LI",
    "name": "Liechtenstein"
  }, {
    "code": "LT",
    "name": "Lithuania"
  }, {
    "code": "LU",
    "name": "Luxembourg"
  }, {
    "code": "MO",
    "name": "Macao"
  }, {
    "code": "MK",
    "name": "Macedonia, the Former Yugoslav Republic of"
  }, {
    "code": "MG",
    "name": "Madagascar"
  }, {
    "code": "MW",
    "name": "Malawi"
  }, {
    "code": "MY",
    "name": "Malaysia"
  }, {
    "code": "MV",
    "name": "Maldives"
  }, {
    "code": "ML",
    "name": "Mali"
  }, {
    "code": "MT",
    "name": "Malta"
  }, {
    "code": "MH",
    "name": "Marshall Islands"
  }, {
    "code": "MQ",
    "name": "Martinique"
  }, {
    "code": "MR",
    "name": "Mauritania"
  }, {
    "code": "MU",
    "name": "Mauritius"
  }, {
    "code": "YT",
    "name": "Mayotte"
  }, {
    "code": "MX",
    "name": "Mexico"
  }, {
    "code": "FM",
    "name": "Micronesia, Federated States of"
  }, {
    "code": "MD",
    "name": "Moldova, Republic of"
  }, {
    "code": "MC",
    "name": "Monaco"
  }, {
    "code": "MN",
    "name": "Mongolia"
  }, {
    "code": "ME",
    "name": "Montenegro"
  }, {
    "code": "MS",
    "name": "Montserrat"
  }, {
    "code": "MA",
    "name": "Morocco"
  }, {
    "code": "MZ",
    "name": "Mozambique"
  }, {
    "code": "MM",
    "name": "Myanmar"
  }, {
    "code": "NA",
    "name": "Namibia"
  }, {
    "code": "NR",
    "name": "Nauru"
  }, {
    "code": "NP",
    "name": "Nepal"
  }, {
    "code": "NL",
    "name": "Netherlands"
  }, {
    "code": "NC",
    "name": "New Caledonia"
  }, {
    "code": "NZ",
    "name": "New Zealand"
  }, {
    "code": "NI",
    "name": "Nicaragua"
  }, {
    "code": "NE",
    "name": "Niger"
  }, {
    "code": "NG",
    "name": "Nigeria"
  }, {
    "code": "NU",
    "name": "Niue"
  }, {
    "code": "NF",
    "name": "Norfolk Island"
  }, {
    "code": "MP",
    "name": "Northern Mariana Islands"
  }, {
    "code": "NO",
    "name": "Norway"
  }, {
    "code": "OM",
    "name": "Oman"
  }, {
    "code": "PK",
    "name": "Pakistan"
  }, {
    "code": "PW",
    "name": "Palau"
  }, {
    "code": "PS",
    "name": "Palestine, State of"
  }, {
    "code": "PA",
    "name": "Panama"
  }, {
    "code": "PG",
    "name": "Papua New Guinea"
  }, {
    "code": "PY",
    "name": "Paraguay"
  }, {
    "code": "PE",
    "name": "Peru"
  }, {
    "code": "PH",
    "name": "Philippines"
  }, {
    "code": "PN",
    "name": "Pitcairn"
  }, {
    "code": "PL",
    "name": "Poland"
  }, {
    "code": "PT",
    "name": "Portugal"
  }, {
    "code": "PR",
    "name": "Puerto Rico"
  }, {
    "code": "QA",
    "name": "Qatar"
  }, {
    "code": "RE",
    "name": "Réunion"
  }, {
    "code": "RO",
    "name": "Romania"
  }, {
    "code": "RU",
    "name": "Russian Federation"
  }, {
    "code": "RW",
    "name": "Rwanda"
  }, {
    "code": "BL",
    "name": "Saint Barthélemy"
  }, {
    "code": "SH",
    "name": "Saint Helena, Ascension and Tristan da Cunha"
  }, {
    "code": "KN",
    "name": "Saint Kitts and Nevis"
  }, {
    "code": "LC",
    "name": "Saint Lucia"
  }, {
    "code": "MF",
    "name": "Saint Martin (French part)"
  }, {
    "code": "PM",
    "name": "Saint Pierre and Miquelon"
  }, {
    "code": "VC",
    "name": "Saint Vincent and the Grenadines"
  }, {
    "code": "WS",
    "name": "Samoa"
  }, {
    "code": "SM",
    "name": "San Marino"
  }, {
    "code": "ST",
    "name": "Sao Tome and Principe"
  }, {
    "code": "SA",
    "name": "Saudi Arabia"
  }, {
    "code": "SN",
    "name": "Senegal"
  }, {
    "code": "RS",
    "name": "Serbia"
  }, {
    "code": "SC",
    "name": "Seychelles"
  }, {
    "code": "SL",
    "name": "Sierra Leone"
  }, {
    "code": "SG",
    "name": "Singapore"
  }, {
    "code": "SX",
    "name": "Sint Maarten (Dutch part)"
  }, {
    "code": "SK",
    "name": "Slovakia"
  }, {
    "code": "SI",
    "name": "Slovenia"
  }, {
    "code": "SB",
    "name": "Solomon Islands"
  }, {
    "code": "SO",
    "name": "Somalia"
  }, {
    "code": "ZA",
    "name": "South Africa"
  }, {
    "code": "GS",
    "name": "South Georgia and the South Sandwich Islands"
  }, {
    "code": "SS",
    "name": "South Sudan"
  }, {
    "code": "ES",
    "name": "Spain"
  }, {
    "code": "LK",
    "name": "Sri Lanka"
  }, {
    "code": "SD",
    "name": "Sudan"
  }, {
    "code": "SR",
    "name": "Suriname"
  }, {
    "code": "SJ",
    "name": "Svalbard and Jan Mayen"
  }, {
    "code": "SZ",
    "name": "Swaziland"
  }, {
    "code": "SE",
    "name": "Sweden"
  }, {
    "code": "CH",
    "name": "Switzerland"
  }, {
    "code": "SY",
    "name": "Syrian Arab Republic"
  }, {
    "code": "TW",
    "name": "Taiwan, Province of China"
  }, {
    "code": "TJ",
    "name": "Tajikistan"
  }, {
    "code": "TZ",
    "name": "Tanzania, United Republic of"
  }, {
    "code": "TH",
    "name": "Thailand"
  }, {
    "code": "TL",
    "name": "Timor-Leste"
  }, {
    "code": "TG",
    "name": "Togo"
  }, {
    "code": "TK",
    "name": "Tokelau"
  }, {
    "code": "TO",
    "name": "Tonga"
  }, {
    "code": "TT",
    "name": "Trinidad and Tobago"
  }, {
    "code": "TN",
    "name": "Tunisia"
  }, {
    "code": "TR",
    "name": "Turkey"
  }, {
    "code": "TM",
    "name": "Turkmenistan"
  }, {
    "code": "TC",
    "name": "Turks and Caicos Islands"
  }, {
    "code": "TV",
    "name": "Tuvalu"
  }, {
    "code": "UG",
    "name": "Uganda"
  }, {
    "code": "UA",
    "name": "Ukraine"
  }, {
    "code": "AE",
    "name": "United Arab Emirates"
  }, {
    "code": "GB",
    "name": "United Kingdom"
  }, {
    "code": "US",
    "name": "United States"
  }, {
    "code": "UM",
    "name": "United States Minor Outlying Islands"
  }, {
    "code": "UY",
    "name": "Uruguay"
  }, {
    "code": "UZ",
    "name": "Uzbekistan"
  }, {
    "code": "VU",
    "name": "Vanuatu"
  }, {
    "code": "VE",
    "name": "Venezuela, Bolivarian Republic of"
  }, {
    "code": "VN",
    "name": "Viet Nam"
  }, {
    "code": "VG",
    "name": "Virgin Islands, British"
  }, {
    "code": "VI",
    "name": "Virgin Islands, US"
  }, {
    "code": "WF",
    "name": "Wallis and Futuna"
  }, {
    "code": "EH",
    "name": "Western Sahara"
  }, {
    "code": "YE",
    "name": "Yemen"
  }, {
    "code": "ZM",
    "name": "Zambia"
  }, {
    "code": "ZW",
    "name": "Zimbabwe"
  }];

  var Enum = Ember.Object.extend({
    init: function init() {
      var choices = this.get('choices');
      // Let each enum choice be available directly on the greater enum object.
      for (var name in choices) {
        this.set(name, choices[name]);
      }
    },
    label: function label(value) {
      var label = void 0;
      var choices = this.get('choices');
      for (var name in choices) {
        if (choices[name].value === value) {
          label = choices[name].label;
          if (!label) {
            return this.makeDefaultLabel(name);
          }
        }
      }
      return label;
    },
    makeDefaultLabel: function makeDefaultLabel(name) {
      return name.split('_').map(function (parts) {
        return parts[0].toUpperCase() + parts.substring(1).toLowerCase();
      }).join(' ');
    },
    values: function values() {
      return Object.entries(this.get('choices')).map(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            attrs = _ref2[1];

        return attrs.value;
      });
    },
    choice: function choice(value) {
      var choices = this.get('choices');
      for (var name in choices) {
        if (choices[name].value === value) {
          return choices[name];
        }
      }
      return null;
    }
  });

  var COUNTRY = exports.COUNTRY = Enum.create({
    choices: countryData.reduce(function (obj, country) {
      var name = country.name.replace(/[.,']+/g, '').replace(/[\s()]+/g, '_').toUpperCase();
      obj[name] = {
        value: country.code,
        label: country.name
      };
      return obj;
    }, {})
  });

  var SUBSCRIPTION_PLAN = exports.SUBSCRIPTION_PLAN = Enum.create({
    choices: {
      FREE: { value: 'free' },
      PRO: {
        value: 'pro-monthly',
        basePrice: 6.00,
        baseQuantity: 30,
        addonPrice: 1.00,
        addonQuantity: 5,
        billingInterval: 'month'
      },
      PRO_YEARLY: {
        value: 'pro-yearly',
        basePrice: 120.00,
        baseQuantity: 20,
        addonPrice: 6.00,
        addonQuantity: 1,
        billingInterval: 'year'
      },
      PRO_YEARLY_CALENDAR_BILLED: {
        value: 'pro-ycb',
        basePrice: 99.00,
        baseQuantity: 25,
        addonPrice: 3.95,
        addonQuantity: 1,
        billingInterval: 'year'
      }
    }
  });

  var COUPON_DISCOUNT = exports.COUPON_DISCOUNT = Enum.create({
    choices: {
      FIXED_AMOUNT: { value: 'fixed_amount' },
      PERCENTAGE: { value: 'percentage' }
    }
  });

  var COUPON_DURATION = exports.COUPON_DURATION = Enum.create({
    choices: {
      ONE_TIME: { value: 'one_time' },
      FOREVER: { value: 'forever' },
      LIMITED_PERIOD: { value: 'limited_period' }
    }
  });

  var SUBSCRIPTION_STATUS = exports.SUBSCRIPTION_STATUS = Enum.create({
    choices: {
      FUTURE: { value: 'future' },
      IN_TRIAL: { value: 'in_trial' },
      ACTIVE: { value: 'active' },
      NON_RENEWING: { value: 'non_renewing' },
      PAUSED: { value: 'paused' },
      CANCELLED: { value: 'cancelled' }
    }
  });

  var OFFER = exports.OFFER = Enum.create({
    choices: {
      CREDIT_CARD: { value: 'cc' },
      NO_CREDIT_CARD: { value: 'no-cc' }
    }
  });

  var COUPON = exports.COUPON = Enum.create({
    choices: {
      FREE_YEAR: { value: 'FREEYEAR' }
    }
  });

  var CARD_BRAND = exports.CARD_BRAND = Enum.create({
    choices: {
      VISA: { value: 'visa' },
      MASTERCARD: { value: 'mastercard' },
      AMERICAN_EXPRESS: { value: 'american_express' },
      DISCOVER: { value: 'discover' },
      JCB: {
        value: 'jcb',
        label: 'JCB'
      },
      DINERS_CLUB: { value: 'diners_club' },
      OTHER: { value: 'other' }
    }
  });

  var GRADE = exports.GRADE = Enum.create({
    choices: {
      PRE_KINDERGARTEN: { value: 'Pre-Kindergarten' },
      KINDERGARTEN: { value: 'Kindergarten' },
      GRADE_1: { value: 'Grade 1' },
      GRADE_2: { value: 'Grade 2' },
      GRADE_3: { value: 'Grade 3' },
      GRADE_4: { value: 'Grade 4' },
      GRADE_5: { value: 'Grade 5' },
      GRADE_6: { value: 'Grade 6' },
      GRADE_7: { value: 'Grade 7' },
      GRADE_8: { value: 'Grade 8' },
      GRADE_9: { value: 'Grade 9' },
      GRADE_10: { value: 'Grade 10' },
      GRADE_11: { value: 'Grade 11' },
      GRADE_12: { value: 'Grade 12' }
    }
  });

  var SHORT_GRADE = exports.SHORT_GRADE = Enum.create({
    choices: {
      PRE_KINDERGARTEN: {
        value: 'PK',
        label: 'Pre-Kindergarten'
      },
      KINDERGARTEN: { value: 'K' },
      GRADE_1: { value: '1' },
      GRADE_2: { value: '2' },
      GRADE_3: { value: '3' },
      GRADE_4: { value: '4' },
      GRADE_5: { value: '5' },
      GRADE_6: { value: '6' },
      GRADE_7: { value: '7' },
      GRADE_8: { value: '8' },
      GRADE_9: { value: '9' },
      GRADE_10: { value: '10' },
      GRADE_11: { value: '11' },
      GRADE_12: { value: '12' }
    }
  });

  var ABBREV_GRADE = exports.ABBREV_GRADE = Enum.create({
    choices: {
      PRE_KINDERGARTEN: {
        value: 'PK',
        label: 'PK'
      },
      KINDERGARTEN: {
        value: 'K',
        label: 'K'
      },
      GRADE_1: {
        value: '1',
        label: '1'
      },
      GRADE_2: {
        value: '2',
        label: '2'
      },
      GRADE_3: {
        value: '3',
        label: '3'
      },
      GRADE_4: {
        value: '4',
        label: '4'
      },
      GRADE_5: {
        value: '5',
        label: '5'
      },
      GRADE_6: {
        value: '6',
        label: '6'
      },
      GRADE_7: {
        value: '7',
        label: '7'
      },
      GRADE_8: {
        value: '8',
        label: '8'
      },
      GRADE_9: {
        value: '9',
        label: '9'
      },
      GRADE_10: {
        value: '10',
        label: '10'
      },
      GRADE_11: {
        value: '11',
        label: '11'
      },
      GRADE_12: {
        value: '12',
        label: '12'
      }
    }
  });

  var AUTH_TAB = exports.AUTH_TAB = Enum.create({
    choices: {
      EMAIL: { value: 'e' },
      SSO: { value: 's', label: 'Single Sign-On' }
    }
  });

  var ACCOUNT_TAB = exports.ACCOUNT_TAB = Enum.create({
    choices: {
      PROFILE: { value: 'profile' },
      PREFERENCES: { value: 'p' },
      BILLING: { value: 'b' }
    }
  });

  var REPORTS_TAB = exports.REPORTS_TAB = Enum.create({
    choices: {
      CLASS_MATRIX: { value: 'm' },
      CLASS_AVERAGES: { value: 'a' },
      STUDENT_PROGRESS: { value: 's' }
    }
  });

  var STUDENT_WIZARD_TAB = exports.STUDENT_WIZARD_TAB = Enum.create({
    choices: {
      SINGLE: { value: 's', label: 'Create Individually' },
      BULK: { value: 'b', label: 'Upload Spreadsheet' }
    }
  });

  var PRELEVEL = exports.PRELEVEL = Enum.create({
    choices: {
      PRE_KINDERGARTEN: {
        value: 'pk',
        score: 0
      },
      KINDERGARTEN_BEGINNING: {
        value: 'ka',
        score: 0.25
      },
      KINDERGARTEN_MIDDLE: {
        value: 'kb',
        score: 0.50
      },
      KINDERGARTEN_END: {
        value: 'kc',
        score: 0.75
      },
      GRADE_1_BEGINNING: {
        value: '1a',
        score: 1.00
      },
      GRADE_1_MIDDLE: {
        value: '1b',
        score: 1.33
      },
      GRADE_1_END: {
        value: '1c',
        score: 1.66
      },
      GRADE_2_BEGINNING: {
        value: '2a',
        score: 2.00
      },
      GRADE_2_MIDDLE: {
        value: '2b',
        score: 2.33
      },
      GRADE_2_END: {
        value: '2c',
        score: 2.66
      }
    }
  });

  var SHORT_PRELEVEL = exports.SHORT_PRELEVEL = Enum.create({
    choices: {
      PRE_KINDERGARTEN: { value: 'pk', label: 'Pre K' },
      KINDERGARTEN_BEGINNING: { value: 'ka', label: 'K Beg' },
      KINDERGARTEN_MIDDLE: { value: 'kb', label: 'K Mid' },
      KINDERGARTEN_END: { value: 'kc', label: 'K End' },
      GRADE_1_BEGINNING: { value: '1a', label: '1 Beg' },
      GRADE_1_MIDDLE: { value: '1b', label: '1 Mid' },
      GRADE_1_END: { value: '1c', label: '1 End' },
      GRADE_2_BEGINNING: { value: '2a', label: '2 Beg' },
      GRADE_2_MIDDLE: { value: '2b', label: '2 Mid' },
      GRADE_2_END: { value: '2c', label: '2 End' }
    }
  });

  var DATE_RANGE_FILTER = exports.DATE_RANGE_FILTER = Enum.create({
    choices: {
      LAST_7_DAYS: { value: '7d' },
      LAST_30_DAYS: { value: '30d' },
      LAST_3_MONTHS: { value: '3m' },
      LAST_6_MONTHS: { value: '6m' }
    }
  });

  var LOGIN_METHOD = exports.LOGIN_METHOD = Enum.create({
    choices: {
      QR_CODE: { value: 'q', label: 'QR Code' },
      CLASS_CODE: { value: 'c', label: 'Class Code' }
    }
  });

  var JOB = exports.JOB = Enum.create({
    choices: {
      TEACHER: {
        value: 'teacher',
        label: 'Teacher'
      },
      SPECIAL_ED_TEACHER: {
        value: 'special_ed_teacher',
        label: 'Teacher, Special Ed'
      },
      COMPUTER_TEACHER: {
        value: 'computer_teacher',
        label: 'Teacher, Computer'
      },
      PRINCIPAL: {
        value: 'principal',
        label: 'Principal'
      },
      ASST_PRINCIPAL: {
        value: 'asst_principal',
        label: 'Principal, Asst/Vice'
      },
      SUPERINTENDENT: {
        value: 'superintendent',
        label: 'Superintendent'
      },
      ASST_SUPERINTENDENT: {
        value: 'asst_superintendent',
        label: 'Superintendent, Asst/Dep'
      },
      CURRICULUM_DIRECTOR: {
        value: 'curriculum_director',
        label: 'Director/Coord, Curriculum'
      },
      INSTR_TECH_DIRECTOR: {
        value: 'instr_tech_director',
        label: 'Director/Coord, Instr Tech'
      },
      TECH_DIRECTOR: {
        value: 'tech_director',
        label: 'Director/Coord, Tech'
      },
      LIBRARIAN: {
        value: 'librarian',
        label: 'Librarian/Media Spec'
      },
      STAFF_DEVELOPER: {
        value: 'staff_developer',
        label: 'Staff Developer'
      },
      CONSULTANT: {
        value: 'consultant',
        label: 'Consultant/Trainer'
      },
      HIGHER_ED: {
        value: 'higher_ed',
        label: 'Higher Ed'
      },
      MATH_SPECIALIST: {
        value: 'math_specialist',
        label: 'Math Specialist'
      },
      MATH_COACH: {
        value: 'math_coach',
        label: 'Math Coord/Coach'
      },
      PARENT: {
        value: 'parent',
        label: 'Parent / Guardian'
      },
      OTHER: {
        value: 'other',
        label: 'Other'
      }
    }
  });

  var SCORE_RATING = exports.SCORE_RATING = Enum.create({
    choices: {
      EXCELLENT: { value: 'e' },
      GOOD: { value: 'g' },
      FAIR: { value: 'f' },
      POOR: { value: 'p' }
    }
  });

  var MODAL = exports.MODAL = Enum.create({
    choices: {
      ARCHIVE_STUDENTS: { value: 'as' },
      UPDATE_TAGS: { value: 'ut' },
      MIGRATE_CLASS_CODE: { value: 'mc' },
      KEEP_CLASS_CODE: { value: 'kc' },
      SUBSCRIPTION_EXPIRED: { value: 'se' }
    }
  });

  var STANDARDS_BODY = exports.STANDARDS_BODY = Enum.create({
    choices: {
      COMMON_CORE: {
        value: 'ccss',
        label: 'CCSS-M'
      },
      NCTM: {
        value: 'nctm',
        label: 'NCTM'
      },
      TEKS: {
        value: 'teks',
        label: 'TEKS'
      }
    }
  });
});