define('client/pods/forgot-password/route', ['exports', 'ember-changeset', 'ember-changeset-validations', 'client/mixins/permissions'], function (exports, _emberChangeset, _emberChangesetValidations, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_permissions.default, {
    setupController: function setupController(controller) {
      var changeset = new _emberChangeset.default(Ember.Object.create(), (0, _emberChangesetValidations.default)(controller.validations), controller.validations);
      controller.setProperties({
        changeset: changeset,
        success: false
      });
    }
  });
});