define('client/pods/group-admin/route', ['exports', 'client/mixins/permissions'], function (exports, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_permissions.default, {
    studentManager: Ember.inject.service(),

    model: function model() {
      return Ember.RSVP.hash({
        groups: this.store.query('group', { is_demo: false }),
        students: this.get('studentManager').fetchStudents(),
        tags: this.store.findAll('tag')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties({
        group: model.groups.get('firstObject'),
        students: model.students.toArray(),
        tags: model.tags
      });
    }
  });
});