define('client/pods/components/table-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var sortDirection = {
    ASC: 'asc',
    DESC: 'desc'
  };

  exports.default = Ember.Component.extend({
    tagName: 'th',
    sortDirection: null,
    title: '',
    isSortable: true,

    isAsc: Ember.computed.equal('sortDirection', sortDirection.ASC),
    isDesc: Ember.computed.equal('sortDirection', sortDirection.DESC),

    isSelected: Ember.computed('selectedHeader', 'headerId', function () {
      return this.get('selectedHeader') === this.get('headerId');
    }),

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('isSelected')) {
        this.set('sortDirection', sortDirection.ASC);
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      if (!this.get('isSelected')) {
        this.set('sortDirection', null);
      }
    },


    actions: {
      sort: function sort() {
        if (!this.get('isSortable')) {
          return;
        }

        // Toggle the table header sort direction if it's currently selected.
        // Otherwise, always set the newly selected column to ascending sort.
        if (this.get('isSelected')) {
          this.set('sortDirection', this.get('isAsc') ? sortDirection.DESC : sortDirection.ASC);
        } else {
          this.set('sortDirection', sortDirection.ASC);
          this.get('selectHeader')(this.get('headerId'));
        }

        this.get('sort')(this.get('isAsc'));
      }
    }
  });
});