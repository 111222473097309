define('client/utils/date', ['exports', 'client/utils/enums'], function (exports, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.rangeToISO = rangeToISO;
  function rangeToISO(rangeVal) {
    var from = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : moment();

    if (!moment.isMoment(from)) {
      throw new Error('from date is not a valid moment object');
    }

    var dateEnumToAttrs = new Map([[_enums.DATE_RANGE_FILTER.LAST_7_DAYS.value, { amount: 7, unit: 'days' }], [_enums.DATE_RANGE_FILTER.LAST_30_DAYS.value, { amount: 30, unit: 'days' }], [_enums.DATE_RANGE_FILTER.LAST_3_MONTHS.value, { amount: 3, unit: 'months' }], [_enums.DATE_RANGE_FILTER.LAST_6_MONTHS.value, { amount: 6, unit: 'months' }]]);

    var _dateEnumToAttrs$get = dateEnumToAttrs.get(rangeVal),
        amount = _dateEnumToAttrs$get.amount,
        unit = _dateEnumToAttrs$get.unit;

    return from.subtract(amount, unit).set({ // Round down to the start of the day
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    }).toISOString();
  }
});