define('client/pods/test-drive/controller', ['exports', 'client/config/environment', 'client/mixins/intro'], function (exports, _environment, _intro) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_intro.default, {
    breadcrumbs: [{ route: 'teacher-dashboard', text: 'Dashboard' }, { text: 'Test Drive' }],

    steps: [{
      text: 'You can play all of the MathBRIX games here. <br><br>' + "We've set up a demo account just for you, so feel free to play " + "with Test Drive as much as you want. It won't affect any of " + "your students' experience."
    }],

    gameURL: Ember.computed('token.token', function () {
      var token = this.get('token.token');
      return _environment.default.gameHost + '?token=' + token;
    })
  });
});