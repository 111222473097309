define('client/initializers/google-authenticator', ['exports', 'client/authenticators/google'], function (exports, _google) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(app) {
    app.register('authenticator:google', _google.default);
  }

  exports.default = {
    name: 'google-authenticator',
    before: 'ember-simple-auth',
    initialize: initialize
  };
});